@tailwind base;
@tailwind components;
@tailwind utilities;

button:focus-visible,
a:focus-visible {
  outline: none;
  @apply ring ring-blue-300;
}

.outlined:not(:focus-visible) {
  @apply ring-1 ring-surface-variant ring-inset disabled:ring-disabled;
}

select {
  appearance: none;

  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;

  padding-block: 6px;
  padding-left: 12px;
  padding-right: 32px;
}

th,
td {
  @apply sm:px-3 py-2 text-center;
}

th {
  @apply typescale-title-small text-on-surface-variant;
}

td {
  @apply typescale-body-medium text-on-surface;
}

.ripple {
  position: absolute; /* The absolute position we mentioned earlier */
  border-radius: 50%;
  transform: scale(0);
  background-color: currentColor;
  pointer-events: none;
  opacity: 0.24;
  animation: ripple 500ms linear;
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}
